<template>
  <CModal
    :title="warehouse.Id ? 'Cập nhật Kho' : 'Thêm Kho'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    size="xl"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <div class="form-row">
          <CCol md="5">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-info" />
                Thông tin cơ bản
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <CInput
                      label="Tên Kho*"
                      placeholder="Tên Kho"
                      v-model="warehouse.Name"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['warehouse.Name'],
                      }"
                      :invalid-feedback="inValidObject['warehouse.Name']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CTextarea
                      rows="2"
                      label="Địa chỉ"
                      placeholder="Địa chỉ"
                      v-model="warehouse.Address"
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['warehouse.Address'],
                      }"
                      :invalid-feedback="inValidObject['warehouse.Address']"
                    />
                  </CCol>
                  <CCol md="12">
                    <CInput
                      v-if="warehouse.Id"
                      label="Cửa hàng"
                      :value="warehouse.Agency ? warehouse.Agency.Name : ''"
                      disabled
                    />
                    <div v-else class="form-group">
                      <label>Cửa hàng</label>
                      <v-select
                        v-model="warehouse.AgencyId"
                        :options="agencies"
                        :reduce="(p) => p.Id"
                        label="Name"
                        placeholder="Chọn cửa hàng"
                        :class="{
                          'is-invalid border-danger rounded': !!inValidObject[
                            'warehouse.AgencyId'
                          ],
                        }"
                      />
                      <div class="invalid-feedback">
                        {{ inValidObject["warehouse.AgencyId"] }}
                      </div>
                    </div>
                  </CCol>
                  <CCol md="12">
                    <CSelect
                      label="Trạng thái"
                      placeholder="Chọn Trạng thái"
                      :value.sync="warehouse.Status"
                      :options="
                        Object.keys($const.STATUS_TEXT).map((_) => {
                          return {
                            value: _,
                            label: $const.STATUS_TEXT[_],
                          };
                        })
                      "
                      :add-input-classes="{
                        'is-invalid': !!inValidObject['warehouse.Status'],
                      }"
                      :invalid-feedback="inValidObject['warehouse.Status']"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="7">
            <CCard>
              <CCardHeader>
                <CIcon name="cil-notes" />
                Thông tin tồn kho
              </CCardHeader>
              <CCardBody>
                <div class="table-responsive-sm">
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th>STT</th>
                        <th>Sản Phẩm</th>
                        <th>Phân loại</th>
                        <th>Tồn Kho</th>
                        <th>Cập Nhật</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="stocks && stocks.length">
                        <tr v-for="(item, i) in stocks" :key="`stock-${i}`">
                          <td>{{ i + 1 }}.</td>
                          <td>
                            {{ item.Product.Name }}
                          </td>
                          <td>
                            {{
                              $const.PRODUCT_TYPES_TEXT[
                                item.Product.ProductType
                              ]
                            }}
                          </td>
                          <td class="text-center font-weight-bold">
                            <span
                              :class="{
                                'text-success': item.StockQty > 0,
                                'text-danger':
                                  item.StockQty <= 0 || !item.StockQty,
                              }"
                            >
                              {{ $func.addCommas(item.StockQty) }}
                            </span>
                          </td>
                          <td>
                            {{
                              $moment
                                .utc(item.UpdatedAt)
                                .local()
                                .format("DD/MM/YYYY HH:mm")
                            }}
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="100%">
                          Chưa có thông tin tồn kho
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="100%" class="text-center">
                          Tổng tồn kho:
                          {{
                            stocks && stocks.length
                              ? $func.addCommas(
                                  stocks.reduce(
                                    (acc, cur) => acc + cur.StockQty,
                                    0
                                  )
                                )
                              : 0
                          }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <CButton
        class="btn btn-primary"
        @click="saveWarehouse"
        :disabled="saving"
      >
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["editing", "warehouseId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      warehouse: this.newWarehouse(),
      saving: false,
      agencies: [],
      stocks: [],
    };
  },
  watch: {
    async warehouseId(val) {
      await this.loadWarehouseInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:warehouseId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  async mounted() {
    this.agencies = await this.loadAgencies();
  },

  methods: {
    newWarehouse() {
      return {
        Id: 0,
        Name: null,
        Address: null,
        AgencyId: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadWarehouseInfo(warehouseId) {
      try {
        this.inValidObject = {};
        if (warehouseId) {
          let filters = [];
          warehouseId && filters.push(`Id eq ${warehouseId}`);
          let resp = await this.$http.get(`odata/InventoryWarehouse`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,Address,AgencyId,Status",
              $expand: `Agency($select=Name),
                InventoryStocks($expand=Product($select=Name,ProductType);$orderby=UpdatedAt desc;$select=StockQty,UpdatedAt)`,
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.warehouse = {
              Id: _.Id,
              Name: _.Name,
              Address: _.Address,
              Status: _.Status.toString(),
              AgencyId: _.AgencyId ? _.AgencyId.toString() : null,
              Agency: _.Agency,
            };
            this.stocks = _.InventoryStocks;
          }
        } else {
          this.warehouse = this.newWarehouse();
          this.stocks = [];
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveWarehouse() {
      let warehouse = this.warehouse;
      this.inValidObject = this.checkValid(warehouse);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let warehouseData = {
          Name: warehouse.Name,
          Address: warehouse.Address,
          Status: parseInt(warehouse.Status),
          AgencyId: parseInt(warehouse.AgencyId),
        };
        // warehouse
        if (!warehouse.Id) {
          // post
          let resp = await this.$http.post(
            `odata/InventoryWarehouse`,
            warehouseData
          );
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/InventoryWarehouse/${warehouse.Id}`,
            warehouseData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(warehouse) {
      let inValidObject = {};

      if (!warehouse.Name)
        inValidObject["warehouse.Name"] = "Vui lòng nhập Tên Kho!";
      if (!warehouse.Address)
        inValidObject["warehouse.Address"] = "Vui lòng nhập Địa chỉ!";
      if (!warehouse.Status)
        inValidObject["warehouse.Status"] = "Vui lòng chọn Trạng Thái!";

      return inValidObject;
    },

    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 200,
          $filter: `Status eq ${active} and InventoryWarehouses/any(i:i/Status eq ${active}) eq false`,
          $select: "Id,Name",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },
  },
};
</script>
