var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.warehouse.Id ? 'Cập nhật Kho' : 'Thêm Kho',"show":_vm.detailModal,"closeOnBackdrop":false,"size":"xl","centered":""},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"5"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-info"}}),_vm._v(" Thông tin cơ bản ")],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên Kho*","placeholder":"Tên Kho","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['warehouse.Name'],
                    },"invalid-feedback":_vm.inValidObject['warehouse.Name']},model:{value:(_vm.warehouse.Name),callback:function ($$v) {_vm.$set(_vm.warehouse, "Name", $$v)},expression:"warehouse.Name"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"rows":"2","label":"Địa chỉ","placeholder":"Địa chỉ","add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['warehouse.Address'],
                    },"invalid-feedback":_vm.inValidObject['warehouse.Address']},model:{value:(_vm.warehouse.Address),callback:function ($$v) {_vm.$set(_vm.warehouse, "Address", $$v)},expression:"warehouse.Address"}})],1),_c('CCol',{attrs:{"md":"12"}},[(_vm.warehouse.Id)?_c('CInput',{attrs:{"label":"Cửa hàng","value":_vm.warehouse.Agency ? _vm.warehouse.Agency.Name : '',"disabled":""}}):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cửa hàng")]),_c('v-select',{class:{
                        'is-invalid border-danger rounded': !!_vm.inValidObject[
                          'warehouse.AgencyId'
                        ],
                      },attrs:{"options":_vm.agencies,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chọn cửa hàng"},model:{value:(_vm.warehouse.AgencyId),callback:function ($$v) {_vm.$set(_vm.warehouse, "AgencyId", $$v)},expression:"warehouse.AgencyId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["warehouse.AgencyId"])+" ")])],1)],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.warehouse.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                        return {
                          value: _,
                          label: _vm.$const.STATUS_TEXT[_],
                        };
                      }),"add-input-classes":{
                      'is-invalid': !!_vm.inValidObject['warehouse.Status'],
                    },"invalid-feedback":_vm.inValidObject['warehouse.Status']},on:{"update:value":function($event){return _vm.$set(_vm.warehouse, "Status", $event)}}})],1)],1)],1)],1)],1),_c('CCol',{attrs:{"md":"7"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-notes"}}),_vm._v(" Thông tin tồn kho ")],1),_c('CCardBody',[_c('div',{staticClass:"table-responsive-sm"},[_c('table',{staticClass:"table table-sm table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("STT")]),_c('th',[_vm._v("Sản Phẩm")]),_c('th',[_vm._v("Phân loại")]),_c('th',[_vm._v("Tồn Kho")]),_c('th',[_vm._v("Cập Nhật")])])]),_c('tbody',[(_vm.stocks && _vm.stocks.length)?_vm._l((_vm.stocks),function(item,i){return _c('tr',{key:("stock-" + i)},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',[_vm._v(" "+_vm._s(item.Product.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$const.PRODUCT_TYPES_TEXT[ item.Product.ProductType ])+" ")]),_c('td',{staticClass:"text-center font-weight-bold"},[_c('span',{class:{
                              'text-success': item.StockQty > 0,
                              'text-danger':
                                item.StockQty <= 0 || !item.StockQty,
                            }},[_vm._v(" "+_vm._s(_vm.$func.addCommas(item.StockQty))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(item.UpdatedAt) .local() .format("DD/MM/YYYY HH:mm"))+" ")])])}):_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(" Chưa có thông tin tồn kho ")])])],2),_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v(" Tổng tồn kho: "+_vm._s(_vm.stocks && _vm.stocks.length ? _vm.$func.addCommas( _vm.stocks.reduce( function (acc, cur) { return acc + cur.StockQty; }, 0 ) ) : 0)+" ")])])])])])])],1)],1)],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveWarehouse}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }