var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilStar"}}),_vm._v(" Danh sách Kho ")],1),(_vm.isAdmin)?_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
            _vm.editing = true;
            _vm.warehouseId = 0;
          })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Kho ")],1):_vm._e()],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.warehouseStatus,"options":Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }),"horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"update:value":function($event){_vm.warehouseStatus=$event}}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Tên Kho","placeholder":"Tên Kho","horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter($event)}},model:{value:(_vm.warehouseName),callback:function ($$v) {_vm.warehouseName=$$v},expression:"warehouseName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"3"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstWarehouse",attrs:{"url":"odata/InventoryWarehouse","filter":_vm.filter,"select":"Id,Name,Address,AgencyId,Status,CreatedAt","sortBy":"CreatedAt desc","expand":"Agency($select=Name)","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Name: {
            field: 'Name',
            display: 'Tên Kho',
            sortable: true,
            style: 'min-width: 150px',
          },
          Address: {
            field: 'Address',
            display: 'Địa chỉ',
            sortable: true,
            style: 'min-width: 200px',
          },
          AgencyName: {
            field: 'Agency/Name',
            display: 'Cửa Hàng',
            sortable: true,
            style: 'min-width: 120px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstWarehouse.page - 1) * _vm.pageSize)+".")]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Name))},on:{"click":function (e) {
                    _vm.warehouseId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Name)+" ")],1)]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":row.Address}},[_vm._v(" "+_vm._s(row.Address)+" ")])]),_c('td',[(row.Agency)?_c('span',[_vm._v(_vm._s(row.Agency.Name))]):_vm._e()]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YY HH:mm"))+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"warehouseId":_vm.warehouseId},on:{"update:editing":function($event){_vm.editing=$event},"update:warehouseId":function($event){_vm.warehouseId=$event},"update:warehouse-id":function($event){_vm.warehouseId=$event},"reload":function($event){return _vm.$refs.lstWarehouse.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }